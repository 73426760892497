import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReCaptcha } from 'react-recaptcha-google';
import { registerUser } from "../../actions/authActions";
import { setCookieData } from "../../actions/dashboardActions";
import classnames from "classnames";
import M from 'materialize-css';
import tellius_logo_full from '../../assets/tellius-logo-full.png';
import Cookies from 'js-cookie';
import _ from 'lodash';
import EmailProviders from './EmailProviders'

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            workEmail: "",
            password: "",
            password2: "",
            isTermsAndConditionsAccepted: false,
            errors: {},
            notARobot: false,
            isSubmitted: false,
            formValid: true,
            isMarketingCookie: false,
            marketingCookie: null
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
    }
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        const cookieData = Cookies.get('telliusMarketing');
        if (cookieData) {
            try {
                const cookie = JSON.parse(decodeURI(cookieData));
                this.props.setCookieData(cookie);
                this.setState({
                    formValid: false,
                    firstName: _.get(cookie, 'first_name'),
                    lastName: _.get(cookie, 'last_name'),
                    workEmail: _.get(cookie, 'business_email'),
                    companyName: _.get(cookie, 'company'),
                    isMarketingCookie: true,
                    marketingCookie: cookie
                });
            } catch (ex) {
                this.setState({
                    formValid: false
                });
            }
        } else {
            this.setState({
                formValid: false
            });
        }
        document.addEventListener('DOMContentLoaded', function () {
            var elems = document.querySelectorAll('.tooltipped');
            var instances = M.Tooltip.init(elems, {
                html: "Please use work email that includes your <br /> company name within it",
                position: "right"
            });
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }
    verifyCallback(recaptchaToken) {
        this.state.notARobot = true;
        this.validateForm();
    }
    expiredCallback(err) {
        this.state.notARobot = false;
        this.validateForm();
    }
    validateForm() {
        var errors = {};
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.workEmail))) {
            errors.workEmail = "Please provide a valid email";
        }
        if (!this.state.firstName) {
            errors.firstName = "First name is required";
        }
        if (this.state.firstName.includes(' ')) {
            errors.firstName = "First name cannot contain spaces.";
        }
        if (!this.state.lastName) {
            errors.lastName = "Last name is required";
        }
        if (this.state.lastName.includes(' ')) {
            errors.lastName = "Last name cannot contain spaces.";
        }
        if (!this.state.companyName) {
            errors.companyName = "Company name is required";
        }
        if (!this.state.isTermsAndConditionsAccepted) {
            errors.isTermsAndConditionsAccepted = "Please accept terms and conditions";
        }
        if (!this.state.notARobot) {
            errors.captcha = "Please verify captcha";
        }
        this.setState({
            errors,
            formValid: Object.keys(errors).length === 0
        });
    }
    
    isPersonalEmail(email) {
        const broken = email.split('@');
        return broken && (EmailProviders.indexOf(broken[1].toLowerCase()) > -1)
    }

    onChange = e => {
        if (e.target.id == "isTermsAndConditionsAccepted") {
            this.validatedOnce = true;
            this.setState({ [e.target.id]: e.target.checked }, this.validateForm);
        } else {
            if (this.validatedOnce) {
                this.setState({ [e.target.id]: e.target.value }, this.validateForm);
            } else {
                this.setState({ [e.target.id]: e.target.value });
            }
        }
    };
    onSubmit = e => {
        e.preventDefault();
        if (!this.state.notARobot) return;

        if(this.state.workEmail && this.isPersonalEmail(this.state.workEmail)){
            var errors = {};
            errors.workEmail = "Please provide your work email";
            this.setState({
                errors,
                formValid: Object.keys(errors).length === 0
            });
            return
        }

        if (this.state.isMarketingCookie) {
            var cookie = this.state.marketingCookie;
            cookie.first_name = this.state.firstName;
            cookie.last_name = this.state.lastName;
            cookie.business_email = this.state.workEmail;
            cookie.company = this.state.companyName;
        }
        const newUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            workEmail: this.state.workEmail,
            password: this.state.password,
            password2: this.state.password2,
            isTermsAndConditionsAccepted: this.state.isTermsAndConditionsAccepted,
            companyName: this.state.companyName,
            marketingData: cookie
        };
        this.props.registerUser(newUser, this.props.history);
        this.setState({ isSubmitted: true });
    };
    render() {
        var { errors, isSubmitted } = this.state;
        if (errors) {
            this.state.isSubmitted = false;
        }
        return (
            <div className="row">
                <div className="heading row">
                    <div className="col s12 m12 l12 row valign-wrapper">
                        <div><b>Create your free </b></div>
                        <img src={tellius_logo_full} alt="logo" style={{ "height": "36px", "margin": "0 5px", "paddingBottom": "2px" }} />
                        <div><b>account</b></div>
                    </div>
                </div>
                <div className="heading-desc row">
                    <div className="col s12 m12 l12">
                        Already have an account? <Link to="/login">Sign in</Link>
                    </div>
                </div>
                <form noValidate onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <span className="valign-wrapper"><label className="tellius-label">Work email </label><i className="material-icons tiny infoIcon tooltipped">info_outline</i></span>
                            <input
                                onChange={this.onChange}
                                value={this.state.workEmail}
                                error={errors.workEmail}
                                id="workEmail"
                                type="email"
                                className={classnames("tellius-input", {
                                    invalid: errors.workEmail
                                })}
                            />
                            <span className="red-text">{errors.workEmail}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 m6 l6">
                            <label className="tellius-label">Your name</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.firstName}
                                error={errors.firstName}
                                placeholder="First name"
                                id="firstName"
                                type="text"
                                className={classnames("tellius-input", {
                                    invalid: errors.firstName
                                })}
                            />
                            <span className="red-text">{errors.firstName}</span>
                        </div>
                        <div className="col s6 m6 l6">
                            <label className="tellius-label">&nbsp;</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.lastName}
                                error={errors.lastName}
                                placeholder="Last name"
                                id="lastName"
                                type="text"
                                className={classnames("tellius-input", {
                                    invalid: errors.lastName
                                })}
                            />
                            <span className="red-text">{errors.lastName}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <label className="tellius-label">Company name</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.companyName}
                                error={errors.companyName}
                                maxLength={14}
                                id="companyName"
                                type="text"
                                className={classnames("tellius-input", {
                                    invalid: errors.companyName
                                })}
                            />
                            <span className="red-text">{errors.companyName}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <ReCaptcha
                                ref={(el) => { this.captchaDemo = el; }}
                                size="normal"
                                render="explicit"
                                sitekey="6Lcmpr8qAAAAANi4KXGfzM1R67p0mStA3naQo4yy"
                                onloadCallback={this.onLoadRecaptcha}
                                verifyCallback={this.verifyCallback}
                                expiredCallback={this.expiredCallback}
                            />
                        </div>
                    </div>
                    <div className="row valign-wrapper">
                        <div className="col s8 m8 l8">
                            <label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.isTermsAndConditionsAccepted}
                                    error={errors.isTermsAndConditionsAccepted}
                                    id="isTermsAndConditionsAccepted"
                                    type="checkbox"
                                    className={classnames("filled-in tellius-checkbox", {
                                        invalid: errors.isTermsAndConditionsAccepted
                                    })}
                                />
                                <span htmlFor="conditions" className={classnames("tellius-label tellius-checkbox-label", {
                                    invalid: errors.isTermsAndConditionsAccepted
                                })}>I agree all <Link to="/terms" target="_blank" style={{ "textDecoration": "underline" }}>terms and conditions</Link></span>
                                <br />
                                <span className="red-text">{errors.isTermsAndConditionsAccepted}</span>
                            </label>
                        </div>
                        <div className="col s4 m4 l4 right-align">
                            <button
                                type="submit"
                                className={classnames("tellius-button btn btn-medium waves-light hoverable", {
                                    hide: isSubmitted,
                                    show: !isSubmitted
                                })}
                                id="signuptellius"
                                disabled={!this.state.formValid}
                            >
                                Sign up
                            </button>
                            <div className={classnames("preloader-wrapper small active", {
                                show: isSubmitted,
                                hide: !isSubmitted
                            })} id="waitCursor">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    setCookieData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { registerUser, setCookieData }
)(withRouter(Register));